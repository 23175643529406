import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import '../css/payStyle.css';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

export default function NewCard() {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchClientSecret = async () => {
            try {

                const user = await fetchUserAttributes();
                console.log('Current authenticated user NewCard:', user.email);

                const result = await fetch("/create-setup-intent", {
                    method: "POST",
                    body: JSON.stringify({ userId: user.email }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const { clientSecret, customerId } = await result.json();
                setClientSecret(clientSecret);
                console.log(clientSecret)
                setCustomerId(customerId); // Set the customer ID
            } catch (error) {
                console.error('Error fetching client secret:', error);
            }
        };

        fetchClientSecret();
    }, []);



    const handleCardSetup = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        try {
            setIsProcessing(true);

            const cardElement = elements.getElement(CardElement);
            if (!cardElement) {
                throw new Error("CardElement is null");
            }

            // Create a PaymentMethod
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                throw new Error(`Create PaymentMethod failed: ${error.message}`);
            }

            // Confirm the SetupIntent on the server using the PaymentMethod ID
            const result = await fetch("/confirm-card-setup", {
                method: "POST",
                body: JSON.stringify({
                    paymentMethodId: paymentMethod.id,
                    setupIntentClientSecret: clientSecret,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const { success, errorMessage } = await result.json();

            if (success) {
                setMessage("Card information saved successfully!");
            } else {
                throw new Error(`Card setup failed: ${errorMessage}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };


    return (
        <form id="payment-form">
            <CardElement id="card-element" />
            <button className="buyCreditsButton" disabled={isProcessing || !stripe || !elements} id="submit" onClick={handleCardSetup}>
                <span id="button-text">
                    {isProcessing ? "Processing ... " : "Save Now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}