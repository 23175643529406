/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:c82db0ba-56c4-4220-bcc4-e7075b6c2284",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_dpwwEaYf0",
    "aws_user_pools_web_client_id": "67kdihhv745vl1shu98s8nlc2m",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_domain": "https://onetoinfinity.auth.eu-west-2.amazoncognito.com"
};


export default awsmobile;
