import react, { useState, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import '../css/regStyle.css';
import awsconfig from '../aws-exports';
import logo from '../images/logo.png';
import pattern from '../images/pattern.png';
import { confirmSignUp, signUp, signIn as amplifySignIn, confirmReset } from 'aws-amplify/auth';
import { generateClient } from "aws-amplify/api";
import ForgotPassword from './ForgotPassword';
Amplify.configure(awsconfig);
function Registration({ setUser }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [tc, setTc] = useState(false);
    const [isRegistered, setIsRegistered] = useState(true);
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationMessage, setVerificationMessage] = useState('');
    const [showVerificationPrompt, setShowVerificationPrompt] = useState(false);
    const [resendClicked, setResendClicked] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showCheckEmail, setShowCheckEmail] = useState(false); 
    const [showEarlyAccess, setShowEarlyAccess] = useState(false);
    const username = email;
    const client = generateClient()

    const submitEarlyAccess = async (email, heard, use) => {

        fetch('https://sheetdb.io/api/v1/relx57lrchfix', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: [
                    {
                        'email': email,
                        'heard': heard,
                        'use': use
                    }
                ]
            })
        })
            .then((response) => response.json())
            .then((data) => console.log(data));


    }

    const handleSignUp = async () => {
        if (password !== passwordConfirmation) {
            alert("Password and Confirmation Password do not match.");
            return;
        }

        if (tc !== true) {
            alert("Please accept Terms & Conditions.");
            return;
        }

        try {
            console.log('Starting sign-up process...');

            const signUpResult = await signUp({
                username,
                email,
                password,
                options: {
                    userAttributes: {
                        email: email,
                        //'custom:credits': '0', 
                    },
                    autoSignIn: true,
                },
            });

            console.log('Sign-up result:', signUpResult);

            const { isSignUpComplete, user, nextStep } = signUpResult;



            if (isSignUpComplete) {
                console.log('Sign-up is complete. User:', user);
                setShowCheckEmail(true);
            } else {
                console.log('Sign-up is not complete. Next step:', nextStep);

                // Handle additional steps if needed

                // Example: Confirming sign-up
                if (nextStep && nextStep.action === 'CONFIRM_SIGN_UP') {
                    console.log('Confirming sign-up...');
                    const confirmSignUpResult = await confirmSignUp({
                        username,
                        confirmationCode: '123456', // Replace with the actual confirmation code
                    });
                    console.log('Confirm sign-up result:', confirmSignUpResult);

                    // Handle confirmation success or failure
                }
            }


        } catch (error) {
            console.log('error signing up:', error);
            // Handle sign-up failure (if needed)
        }
    };

    const handleConfirmCode = async (username, confirmationCode) => {
    }

    const handleResendVerificationCode = async () => {

    };

    const handleSignIn = async () => {
        try {
            const { isSignedIn, nextStep } = await amplifySignIn({ username: email, password });
            window.location.reload();
            // Handle sign-in success
        } catch (error) {
            console.log('error signing in', error.message);
            // Handle sign-in failure

        }
    };

    const handleForgotPassword = async (e) => {

    };

    const toggleVerifForm = () => {
        setShowVerificationPrompt(true);
    }

    const toggleForm = () => {
        setIsRegistered(!isRegistered);
    };

    return (
        

        <div className="container">
            

            <img src={pattern} alt="pattern" className="pattern" />
            <img src={pattern} alt="pattern" className="pattern2" />

            <form className="regForm" onSubmit={(e) => {
                e.preventDefault(); // Prevent default form submission behavior
                isRegistered ? handleSignIn() : handleSignUp();
            }}>
                <img src={logo} alt="Logo" className="logo-image" />
                <h2>{isRegistered ? 'Welcome! Lets login in' : 'Lets create an account'}</h2>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {
                    !isRegistered && ( <>
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                        />
                        
                        <label>
                            <input
                                type="checkbox"
                                checked={tc}
                                onChange={(e) => setTc(e.target.checked)}
                            />
                            I accept the <span className="terms">terms</span> and <span className="conditions">conditions</span>.
                        </label>
                    </>)
                }

                {showCheckEmail &&
                    <div className="checkEmail">
                        <p>Check Your Email To Confirm Sign Up</p>
                    </div>
                }

                {isRegistered && (
                    <button className="forgot-password-btn" onClick={() => setShowForgotPassword(true)} type="button">Forgot Password?</button>
                    
                )}

                <div className="submitContainer">
                    {/*<p className="regToggle" onClick={toggleForm}>*/}
                    {/*    {isRegistered ? 'Register Now' : 'Already registered?'}*/}
                    {/*</p>*/}
                    <button className="regSubmit" type="submit">{isRegistered ? 'Login' : 'Register'}</button>

                    <button className="ApplyEarly" type="button" onClick={() => setShowEarlyAccess(true)}>Apply For Early Access</button>
                </div>

                {showForgotPassword && (
                    <div className="forgotPassContainerMain">
                        <h2>Reset Password</h2>
                        <ForgotPassword
                            onCancel={() => setShowForgotPassword(false)}
                        />
                    </div>
                )}
            </form>

            <div className="earlyAccess" style={{ display: showEarlyAccess ? 'block' : 'none' }}>
                <div className="confirmAccess">
                    <div className="flexCon">
                        <h2>Apply For Early Access</h2>
                        <button className="exitEarly" onClick={() => setShowEarlyAccess(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M1 13L13 1M1 1L13 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                    
                    
                    <form className="earlyAccessForm">
                        <input type="email" placeholder="Email" required></input>
                        <input placeholder="How you heard about us?" required></input>
                        <input placeholder="What do you plan to use the platform for?" required></input>

                        <button onClick={() => { 
                            const emailInput = document.querySelector('.earlyAccessForm input[type="email"]').value;
                            const heardInput = document.querySelector('.earlyAccessForm input[placeholder="How you heard about us?"]').value;
                            const useInput = document.querySelector('.earlyAccessForm input[placeholder="What do you plan to use the platform for?"]').value;

                            submitEarlyAccess(emailInput, heardInput, useInput);
                            setShowEarlyAccess(false);
                        }}
                        >Submit</button>
                    </form>
                </div>
            </div>

            <div className="overlay" style={{ display: showEarlyAccess ? 'block' : 'none' }}></div>
        </div>
    );
}

export default Registration;