import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import Registration from './components/Registration';
import Dashboard from './components/Dashboard';
import { signOut, getCurrentUser } from 'aws-amplify/auth';

Amplify.configure(awsconfig);

function App() {
    const [page, setPage] = useState('home');
    const [user, setUser] = useState(null);


    useEffect(() => {
        const checkAuth = async () => {
            try {
                const user = await getCurrentUser();
                console.log('Current authenticated user:', user);
                // Set the user state or perform other actions as needed

                setUser(user);
            } catch (error) {
                // No authenticated user
                console.log('No authenticated user:', error);
            }
        };

        checkAuth();
    }, []);

    

    const handleSignUpClick = () => {
        setPage('signup');
    }

    async function handleSignOut() {
        try {
            await signOut();
            setUser(null);
            window.location.reload();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <div className="App">
            {user ? (
                <Dashboard />
            ) : (
                <Registration setUser={setUser} />
            )}
        </div>
    );
}

export default App;
