import React, { useState, useEffect } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import '../css/payMethodsStyle.css';
import Card from './Card';
import { loadStripe } from "@stripe/stripe-js";
import NewCard from './NewCard'; 
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import Visa from '../images/visa.png';
import Master from '../images/visa.png';

function PayMethods({ onBack }) {
	const [isProcessing, setProcessingTo] = useState(false);
	const [checkoutError, setCheckoutError] = useState();
	const [showSuccessfulMethod, setShowSuccessfulMethod] = useState(false);
	const [stripePromise, setStripePromise] = useState(null);
	const [clientSecret, setClientSecret] = useState("");
	const [showPaymentForm, setShowPaymentForm] = useState(false);
	const [userId, setUserId] = useState(""); 
	const [userCards, setUserCards] = useState([]);
	const [userEmail, setUserEmail] = useState("");
	const [showDelEdit, setShowDelEdit] = useState(false);
	async function currentAuthenticatedUser() {
		try {
			const { userId } = await getCurrentUser();
			setUserId(userId); // Set user ID in state
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		currentAuthenticatedUser()
		const fetchConfig = async () => {
			try {
				const response = await fetch("/config");
				const { publishableKey } = await response.json();
				setStripePromise(loadStripe(publishableKey));
			} catch (error) {
				console.error('Error fetching config:', error);
			}
		};

		fetchConfig();
	}, []);

	useEffect(() => {
		const fetchPaymentIntent = async () => {
			try {
				const result = await fetch("/create-payment-intent", {
					method: "POST",
					body: JSON.stringify({}),
				});
				const { clientSecret } = await result.json();
				setClientSecret(clientSecret);
			} catch (error) {
				console.error('Error fetching payment intent:', error);
			}
		};

		fetchPaymentIntent();
	}, []);

	const stripe = useStripe();
	const elements = useElements();

	const handleAddPaymentMethod = async () => {
		// Show the payment form
		setShowPaymentForm(true);
	};

	const handlePaymentFormClose = () => {
		setShowPaymentForm(false);
	};

	const handlePaymentFormSubmit = async () => {
		// Handle form submission, e.g., save card details
		try {
			setProcessingTo(true);

			const user = await getCurrentUser();
			const { error } = await stripe.confirmCardSetup(
				clientSecret, {
				payment_method: {
					card: elements.getElement('card'), // Use the element from NewCard component
					customer: user.attributes.stripeCustomerId, // Use the customer ID from the user attributes
				},
			});

			if (error) {
				setCheckoutError(error.message);
				setProcessingTo(false);
			} else {
				// Handle successful setup
				setShowPaymentForm(false);
				setShowSuccessfulMethod(true);
			}
		} catch (error) {
			console.error('Error confirming card setup:', error);
			setCheckoutError('Error confirming card setup');
			setProcessingTo(false);
		}
	};

	useEffect(() => {
		const fetchUserCards = async () => {
			try {
				const user = await fetchUserAttributes();
				setUserEmail(user.email);
				console.log(user.email);

				const result = await fetch("/get-user-cards", {
					method: "POST",
					body: JSON.stringify({ userEmail: user.email }),
					headers: {
						"Content-Type": "application/json",
					},
				});
				if (result.ok) {
					const data = await result.json();

					if (data.cards && Array.isArray(data.cards)) {
						setUserCards(data.cards);
						console.log(userCards);
					} else {
						console.error('Invalid or missing "cards" property in the server response:', data);
					}
				} else {
					console.error('Error fetching user cards. Server returned:', result.statusText);
				}

			} catch (error) {
				console.error('Error fetching user cards:', error);
			}
		};

		fetchUserCards();
	}, []);

	const handleSuccessfulMethodClose = () => {
		setShowSuccessfulMethod(false);
	};

	const handleCloseSetupForm = () => {
		setShowPaymentForm(false);
	}

	const handleShowDelEdit = () => {
		setShowDelEdit(!showDelEdit);
	}

	return (
		<div className="payMethods">
			<button className="backBtn" onClick={onBack}>
				<svg className="backArrowIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path d="M10.2222 6L4 12M4 12L10.2222 18M4 12H20" stroke="#090516" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
				<span className="backText">Back</span></button>
			<h1>Payment Methods</h1>

			<div className="cardMethods">
				<div className="outIn">
					
				</div>
				<div className="cardsContainer">
					<ul>
						{userCards && userCards.map(card => (
							<li className="cardsList" key={card.card.id}>
								<div className="cardDisplayInfo">
									<div className="bankImage">
										{card.card.brand === 'visa' && (
											<img src={Visa} alt="Visa" className="cardImage" />
										)}
										{card.card.brand === 'mastercard' && (
											<img src={Master} alt="MasterCard" className="cardImage" />
										)}
										{!(card.card.brand === 'visa' || card.card.brand === 'mastercard') && (
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
												<path d="M8 14.8682V17.8682M12 14.8682V17.8682M16 14.8682V17.8682M3 21.8682H21M3 10.8682H21M3 7.86816L12 3.86816L21 7.86816M4 10.8682H20V21.8682H4V10.8682Z" stroke="#090516" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										)}
									</div>
									<div className="cardDetails">
										Card ending in {card.card.last4} <span className="expDateText"><br></br>Expires on: {card.card.exp_month}/{card.card.exp_year}</span>
									</div>
									
								</div>

								<div className="cardControls">
									<button onClick={handleShowDelEdit}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M12 5V5.01M12 12V12.01M12 19V19.01M12 6C11.7348 6 11.4804 5.89464 11.2929 5.70711C11.1054 5.51957 11 5.26522 11 5C11 4.73478 11.1054 4.48043 11.2929 4.29289C11.4804 4.10536 11.7348 4 12 4C12.2652 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5C13 5.26522 12.8946 5.51957 12.7071 5.70711C12.5196 5.89464 12.2652 6 12 6ZM12 13C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11C12.2652 11 12.5196 11.1054 12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12C13 12.2652 12.8946 12.5196 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13ZM12 20C11.7348 20 11.4804 19.8946 11.2929 19.7071C11.1054 19.5196 11 19.2652 11 19C11 18.7348 11.1054 18.4804 11.2929 18.2929C11.4804 18.1054 11.7348 18 12 18C12.2652 18 12.5196 18.1054 12.7071 18.2929C12.8946 18.4804 13 18.7348 13 19C13 19.2652 12.8946 19.5196 12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20Z" stroke="#090516" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</button>
								</div>

								<div className="delEdit" style={{display: showDelEdit ? 'block' : 'none'}}>
									<button>Edit</button>
									<div className="divider"></div>
									<button>Delete</button>
								</div>
							</li>
						))}
					</ul>

				</div>
			</div>

			<div className="addCards">
				<button className="addPayMethod" onClick={handleAddPaymentMethod}>Add Payment Method</button>
			</div>

			<div className="setUpPaymentMethods">
				{showPaymentForm && (
					<div className="setupFormContainer">
						<div className="setupForm">
							<div className="seperator">
								<h2>Set up payment method</h2>
								<button className="closeButtonSetupForm" onClick={handleCloseSetupForm}>
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
										<path d="M1 13L13 1M1 1L13 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</button>
							</div>
							
							<Elements stripe={stripePromise} options={{ clientSecret }}>
								<NewCard onClose={handlePaymentFormClose} onSubmit={handlePaymentFormSubmit} />
							</Elements>
						</div>
						<div className="overlay"></div>
					</div>

					
				)}
				
				<div className="successfulMethod" style={{ display: showSuccessfulMethod ? 'block' : 'none' } }>
					<svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
						<path d="M19.0016 26.9996L24.3349 32.3329L35.0016 21.6663M15.8949 7.52494C17.8077 7.37227 19.6236 6.62014 21.0842 5.3756C22.7347 3.96832 24.8326 3.19531 27.0016 3.19531C29.1705 3.19531 31.2685 3.96832 32.9189 5.3756C34.3795 6.62014 36.1954 7.37227 38.1082 7.52494C40.2702 7.69694 42.3002 8.6338 43.8338 10.1674C45.3674 11.701 46.3042 13.7309 46.4762 15.8929C46.6289 17.8058 47.381 19.6217 48.6256 21.0823C50.0328 22.7327 50.8058 24.8307 50.8058 26.9996C50.8058 29.1686 50.0328 31.2665 48.6256 32.9169C47.381 34.3775 46.6289 36.1935 46.4762 38.1063C46.3042 40.2683 45.3674 42.2982 43.8338 43.8318C42.3002 45.3654 40.2702 46.3023 38.1082 46.4743C36.1954 46.6269 34.3795 47.3791 32.9189 48.6236C31.2685 50.0309 29.1705 50.8039 27.0016 50.8039C24.8326 50.8039 22.7347 50.0309 21.0842 48.6236C19.6236 47.3791 17.8077 46.6269 15.8949 46.4743C13.7329 46.3023 11.7029 45.3654 10.1694 43.8318C8.63575 42.2982 7.69889 40.2683 7.52689 38.1063C7.37422 36.1935 6.62209 34.3775 5.37756 32.9169C3.97027 31.2665 3.19727 29.1686 3.19727 26.9996C3.19727 24.8307 3.97027 22.7327 5.37756 21.0823C6.62209 19.6217 7.37422 17.8058 7.52689 15.8929C7.69889 13.7309 8.63575 11.701 10.1694 10.1674C11.7029 8.6338 13.7329 7.69694 15.8949 7.52494Z" stroke="#00FF1A" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>

					<h2>Payment method saved successfully!</h2>
					<button onClick={handleSuccessfulMethodClose}>Okay</button>
				</div>
			</div>
		</div>
	)
}

export default PayMethods;