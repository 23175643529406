import react, { useState, useEffect } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import '../css/regStyle.css';
function ForgotPassword({ onCancel }) {
    const [email, setEmail] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newConfPassword, setNewConfPassword] = useState("");
    const [resetStep, setResetStep] = useState("submitEmail");
    const [resetSuccess, setResetSuccess] = useState(false);

    async function handleResetPassword() {
        try {
            const output = await resetPassword({ username: email });
            handleResetPasswordNextSteps(output);
        } catch (error) {
            console.log(error);
        }
    }

    async function handleConfirmResetPassword() {
        if (newPassword !== newConfPassword) {
            console.log("Passwords don't match.")
            return
        };

        try {
            await confirmResetPassword({
                username: email,
                confirmationCode,
                newPassword
            });
            console.log('Successfully reset password.');
            setResetSuccess(true);

        } catch (error) {
            console.log(error);
        }
    }

    function handleResetPasswordNextSteps(output) {
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                console.log(
                    `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
                );
                // You can now show a form for the user to input the confirmation code and new password.
                // Also, provide a button to trigger the handleConfirmResetPassword function.
                break;
            case 'DONE':
                console.log('Successfully reset password.');
                break;
            default:
                console.log('Unexpected reset password step.');
        }
    }
    function handleResetPasswordNextSteps(output) {
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                console.log(
                    `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
                );
                setResetStep("confirmCode");
                break;
            case 'DONE':
                console.log('Successfully reset password.');
                break;
            default:
                console.log('Unexpected reset password step.');
        }
    }
    const renderForm = () => {
        if (resetSuccess) {
            return <p>Password reset successfully! Close this window.</p>;
        }

        switch (resetStep) {
            case 'submitEmail':
                return (
                    <form className="resetForm">
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type="button" onClick={handleResetPassword}>
                            Submit
                        </button>
                    </form>
                );
            case 'confirmCode':
                return (
                    <form className="resetForm">
                        <input
                            placeholder="Code"
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                        <button onClick={() => setResetStep('resetPassword')}>
                            Next
                        </button>
                    </form>
                );
            case 'resetPassword':
                return (
                    <form className="resetForm">
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={newConfPassword}
                            onChange={(e) => setNewConfPassword(e.target.value)}
                        />
                        <button type="button" onClick={handleConfirmResetPassword}>
                            Confirm Reset
                        </button>
                    </form>
                );
            default:
                return null;
        }
    };



    return (
        <div className="ForgotPassContainer">
            <button className="cancel" type="button" onClick={onCancel}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M1 13L13 1M1 1L13 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
            {renderForm()}
        </div>
    );
}

export default ForgotPassword;