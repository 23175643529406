import React, { useState, useEffect } from 'react';
import '../css/netStyle.css';
import AWS from 'aws-sdk';
import { getCurrentUser } from 'aws-amplify/auth';

const awsSecret = process.env.REACT_APP_AWS_SECRET_KEY;
const awsAccess = process.env.REACT_APP_AWS_ACCESS_KEY;
const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsTable = process.env.REACT_APP_AWS_TABLE_NAME;

const wasabiSecret = process.env.REACT_APP_WASABI_SECRET_KEY;
const wasabiAccess = process.env.REACT_APP_WASABI_ACCESS_KEY;
const wasabiRegion = process.env.REACT_APP_WASABI_REGION;
const wasabiBucket = process.env.REACT_APP_WASABI_BUCKET_NAME;

function Network() {
    const [connected, setConnected] = useState(false);
    const [activeButton, setActiveButton] = useState('Receive');
    const [activeConnection, setActiveConnection] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showUploadComp, setShowUploadComp] = useState(false);
    const [pendingButton, setPendingButton] = useState(null);
    const [droppedFile, setDroppedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [user, setUser] = useState(null);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const user = await getCurrentUser();

                setUser(user);
            } catch (error) {
                // No authenticated user
                console.log('No authenticated user:', error);
            }
        };

        checkAuth();
    }, []);

    const generateUID = () => {
        // This is a simple example, you may need a more robust solution for production
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    };

    const handleSubmitProject = async () => {
        const fileInput = document.querySelector('input[type="file"]');
        const file = fileInput.files[0];

        if (isUploading) {
            setError('A file is already being uploaded. Please wait.');
            setShowError(true);
            return;
        }

        if (file) {
            //if (!file.name.toLowerCase().endsWith('.blend')) {
            //    setError('Invalid file type. Please select a .blend file.');
            //    setShowError(true);
            //    return;
            //}

            console.log('File to be submitted:', file);

            //generate uID for the file
            const uId = generateUID();
            console.log("UID: ", uId)

            setIsUploading(true);

            //Upload to Wasabi
            try {
                const accessKeyId = wasabiAccess;
                const secretAccessKey = wasabiSecret;
                const bucketName = wasabiBucket;
                const region = wasabiRegion;

                //const accessKeyId = "ZZ7H4RGFLEMEAQOA8F79";
                //const secretAccessKey = "bGDj1eIGOnuoI96BKmGTmpVOh8VnIZdbAioG3HA5";
                //const bucketName = "oti-v0";
                //const region = "eu-west-1";

                // Set an endpoint.
                const ep = new AWS.Endpoint('s3.eu-west-1.wasabisys.com');

                // Create an S3 client
                const s3 = new AWS.S3({
                    endpoint: ep,
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey,
                    region: region,
                    logger: console
                });

                // set key and bucket.
                const object_upload_params = {
                    Bucket: "oti-v0",
                    Key: uId,
                    Body: file
                };

                // upload object to previously created "examplebucket"
                s3.putObject(object_upload_params, function (err, data) {
                    if (err) {
                        console.error('Error during file upload:', err);
                    } else {
                        console.log('File uploaded successfully:', data);

                        //Update AWS Table
                        const dynamoDb = new AWS.DynamoDB({
                            region: awsRegion,
                            accessKeyId: awsAccess,
                            secretAccessKey: awsSecret,
                        });

                        const params = {
                            TableName: "Projects",
                            Item: {
                                ProjectId: { S: uId },
                                UserId: { S: user.userId },
                                StatusId: { S: "0" }
                            },
                        };

                        dynamoDb.putItem(params, function (err, data) {
                            if (err) {
                                console.error('Error updating DynamoDB table:', err);
                            } else {
                                console.log('DynamoDB table updated successfully:', data);
                            }
                        });

                        //Show Uploaded Screen
                        setIsUploading(false);
                        setShowUploadComp(true);
                        fileInput.value = '';
                    }
                }).on('httpUploadProgress', function (progress) {
                    console.log('Upload progress:', progress);
                    const percentage = Math.round((progress.loaded / progress.total) * 100);
                    setUploadProgress(percentage);
                });

            } catch (error) {
                console.error('Error during file upload:', error);
            }

        } else {
            console.error('No file selected.');
        }

    };

    const handleConnectionBtn = (button) => {
        setActiveConnection(button);
    }

    const handleErrorContinue = () => {
        setShowError(false);
    }

    const handleButtonClick = (button) => {
        
        if (showConfirmation) {
            return;
        }
        setPendingButton(button);
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
        setPendingButton(null);
    };

    const handleContinue = () => {
        setShowConfirmation(false);
        setActiveButton(pendingButton);
        setPendingButton(null);
    };

    const handleDone = () => {
        setUploadProgress(0);
        setShowUploadComp(false);

        setTimeout(() => {
            setShowUploadComp(false);
        }, 5000);
    }

    const handleFileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        // Assuming you want to handle only the first dropped file
        const file = files[0];
        setDroppedFile(file);
    };

    const handleRemoveFile = () => {
        setDroppedFile(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div className="networkContainer">
            <h2>Network</h2>
            <div className="topContainer">
                <div className="connect">
                    {/*<div className="infoTop">*/}
                    {/*    <p>You are currently disconnected</p>*/}
                    {/*    <svg className="networkIcon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">*/}
                    {/*        <path d="M9.02344 19.6943V13.6943C9.02344 13.1639 8.81272 12.6552 8.43765 12.2801C8.06258 11.9051 7.55387 11.6943 7.02344 11.6943H5.02344C4.493 11.6943 3.9843 11.9051 3.60922 12.2801C3.23415 12.6552 3.02344 13.1639 3.02344 13.6943V19.6943C3.02344 20.2248 3.23415 20.7335 3.60922 21.1085C3.9843 21.4836 4.493 21.6943 5.02344 21.6943H7.02344C7.55387 21.6943 8.06258 21.4836 8.43765 21.1085C8.81272 20.7335 9.02344 20.2248 9.02344 19.6943ZM9.02344 19.6943V9.69434C9.02344 9.1639 9.23415 8.6552 9.60922 8.28012C9.9843 7.90505 10.493 7.69434 11.0234 7.69434H13.0234C13.5539 7.69434 14.0626 7.90505 14.4377 8.28012C14.8127 8.6552 15.0234 9.1639 15.0234 9.69434V19.6943M9.02344 19.6943C9.02344 20.2248 9.23415 20.7335 9.60922 21.1085C9.9843 21.4836 10.493 21.6943 11.0234 21.6943H13.0234C13.5539 21.6943 14.0626 21.4836 14.4377 21.1085C14.8127 20.7335 15.0234 20.2248 15.0234 19.6943M15.0234 19.6943V5.69434C15.0234 5.1639 15.2342 4.6552 15.6092 4.28012C15.9843 3.90505 16.493 3.69434 17.0234 3.69434H19.0234C19.5539 3.69434 20.0626 3.90505 20.4377 4.28012C20.8127 4.6552 21.0234 5.1639 21.0234 5.69434V19.6943C21.0234 20.2248 20.8127 20.7335 20.4377 21.1085C20.0626 21.4836 19.5539 21.6943 19.0234 21.6943H17.0234C16.493 21.6943 15.9843 21.4836 15.6092 21.1085C15.2342 20.7335 15.0234 20.2248 15.0234 19.6943Z" stroke="#F53E05" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />*/}
                    {/*    </svg>*/}
                    {/*</div>*/}
                    

                    {/*<div className="switch">*/}
                    {/*    <button*/}
                    {/*        className={activeButton === 'Receive' ? 'activeSwitch' : ''}*/}
                    {/*        onClick={() => handleButtonClick('Receive')}*/}
                    {/*    >*/}
                    {/*        Receive*/}
                    {/*    </button>*/}
                    {/*    <button*/}
                    {/*        className={activeButton === 'Provide' ? 'activeSwitch' : ''}*/}
                    {/*        onClick={() => handleButtonClick('Provide')}*/}
                    {/*    >*/}
                    {/*        Provide*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    {/*<div className="connectBtnContainer">*/}
                    {/*    <button className={activeConnection === true ? 'activeConnection' : ''} onClick={() => handleConnectionBtn(!activeConnection)} >*/}
                    {/*        <svg  xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">*/}
                    {/*            <path  d="M0 0H56V56H0V0Z" fill="white" fill-opacity="0.01" />*/}
                    {/*            <path className={activeConnection === true ? 'activeConnectionIcon' : ''} d="M16.9056 9.33301C16.1363 9.77228 15.3962 10.2559 14.6889 10.7803C13.4785 11.6777 12.3642 12.6946 11.3639 13.8132C7.915 17.6704 5.82227 22.737 5.82227 28.2861C5.82227 40.3702 15.7466 50.1663 27.9889 50.1663C40.2312 50.1663 50.1556 40.3702 50.1556 28.2861C50.1556 22.737 48.0628 17.6704 44.6139 13.8132C43.6136 12.6946 42.4993 11.6777 41.2889 10.7803C40.5817 10.2559 39.8416 9.77228 39.0723 9.33301" stroke="#D0D0D0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />*/}
                    {/*            <path className={activeConnection === true ? 'activeConnectionIcon' : ''} d="M27.9785 4.66943V28.0028" stroke="#D0D0D0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />*/}
                    {/*        </svg>*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    <div className="dropFile" onDragOver={(e) => e.preventDefault()} onDrop={handleFileDrop}>
                        <input type="file" />
                        <button type="submit" onClick={handleSubmitProject}>Submit</button>
                        {/*<p>or drag and drop files here</p>*/}
                        {/*{droppedFile && (*/}
                        {/*    <div className="droppedFileContainer">*/}
                        {/*        <p>{droppedFile.name}</p>*/}
                        {/*        <button onClick={handleRemoveFile}>Remove</button>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        <div className="progress-container">
                            <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                        </div>

                    </div>
                    
                    
                </div>
            </div>

            <div className="error-container" style={{ display: showError ? 'block' : 'none' }}>
                <div className="confirmText">
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                        <path d="M52 12H12V52H52V12ZM19 40H17V38H19V40ZM19 33H17V31H19V33ZM19 26H17V24H19V26ZM42 42H22V22H42V42ZM47 40H45V38H47V40ZM47 33H45V31H47V33ZM47 26H45V24H47V26Z" fill="black" />
                        <path d="M40 24H24V40H40V24Z" fill="black" />
                        <path d="M19 3H17V9H19V3Z" fill="black" />
                        <path d="M26 3H24V9H26V3Z" fill="black" />
                        <path d="M33 3H31V9H33V3Z" fill="black" />
                        <path d="M40 3H38V9H40V3Z" fill="black" />
                        <path d="M47 3H45V9H47V3Z" fill="black" />
                        <path d="M19 55H17V61H19V55Z" fill="black" />
                        <path d="M26 55H24V61H26V55Z" fill="black" />
                        <path d="M33 55H31V61H33V55Z" fill="black" />
                        <path d="M40 55H38V61H40V55Z" fill="black" />
                        <path d="M47 55H45V61H47V55Z" fill="black" />
                        <path d="M9 45H3V47H9V45Z" fill="black" />
                        <path d="M9 38H3V40H9V38Z" fill="black" />
                        <path d="M9 31H3V33H9V31Z" fill="black" />
                        <path d="M9 24H3V26H9V24Z" fill="black" />
                        <path d="M9 17H3V19H9V17Z" fill="black" />
                        <path d="M61 45H55V47H61V45Z" fill="black" />
                        <path d="M61 38H55V40H61V38Z" fill="black" />
                        <path d="M61 31H55V33H61V31Z" fill="black" />
                        <path d="M61 24H55V26H61V24Z" fill="black" />
                        <path d="M61 17H55V19H61V17Z" fill="black" />
                    </svg>

                    <h2 className="errorText">Error!</h2>
                    <p className="errorText">{error}</p>
                </div>

                <div className="uploadBtns">
                    <button onClick={handleErrorContinue}>Continue</button>
                </div>
            </div>

            <div className="confirmContainer" style={{ display: showConfirmation ? 'block' : 'none' }}>
                <div className="confirmText">
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                        <path d="M52 12H12V52H52V12ZM19 40H17V38H19V40ZM19 33H17V31H19V33ZM19 26H17V24H19V26ZM42 42H22V22H42V42ZM47 40H45V38H47V40ZM47 33H45V31H47V33ZM47 26H45V24H47V26Z" fill="black" />
                        <path d="M40 24H24V40H40V24Z" fill="black" />
                        <path d="M19 3H17V9H19V3Z" fill="black" />
                        <path d="M26 3H24V9H26V3Z" fill="black" />
                        <path d="M33 3H31V9H33V3Z" fill="black" />
                        <path d="M40 3H38V9H40V3Z" fill="black" />
                        <path d="M47 3H45V9H47V3Z" fill="black" />
                        <path d="M19 55H17V61H19V55Z" fill="black" />
                        <path d="M26 55H24V61H26V55Z" fill="black" />
                        <path d="M33 55H31V61H33V55Z" fill="black" />
                        <path d="M40 55H38V61H40V55Z" fill="black" />
                        <path d="M47 55H45V61H47V55Z" fill="black" />
                        <path d="M9 45H3V47H9V45Z" fill="black" />
                        <path d="M9 38H3V40H9V38Z" fill="black" />
                        <path d="M9 31H3V33H9V31Z" fill="black" />
                        <path d="M9 24H3V26H9V24Z" fill="black" />
                        <path d="M9 17H3V19H9V17Z" fill="black" />
                        <path d="M61 45H55V47H61V45Z" fill="black" />
                        <path d="M61 38H55V40H61V38Z" fill="black" />
                        <path d="M61 31H55V33H61V31Z" fill="black" />
                        <path d="M61 24H55V26H61V24Z" fill="black" />
                        <path d="M61 17H55V19H61V17Z" fill="black" />
                    </svg>

                    <h2>You are switching to providing</h2>
                    <p>Are you sure? This way youll be providing from the network instead of receiving.</p>
                </div>
                <div className="confirmBtns">
                    <button onClick={handleCancel}>Cancel</button>
                    <button onClick={handleContinue}>Continue</button>
                </div>
            </div>

            <div className="completeContainer" style={{ display: showUploadComp ? 'block' : 'none' }}>
                <div className="confirmText">
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                        <path d="M52 12H12V52H52V12ZM19 40H17V38H19V40ZM19 33H17V31H19V33ZM19 26H17V24H19V26ZM42 42H22V22H42V42ZM47 40H45V38H47V40ZM47 33H45V31H47V33ZM47 26H45V24H47V26Z" fill="black" />
                        <path d="M40 24H24V40H40V24Z" fill="black" />
                        <path d="M19 3H17V9H19V3Z" fill="black" />
                        <path d="M26 3H24V9H26V3Z" fill="black" />
                        <path d="M33 3H31V9H33V3Z" fill="black" />
                        <path d="M40 3H38V9H40V3Z" fill="black" />
                        <path d="M47 3H45V9H47V3Z" fill="black" />
                        <path d="M19 55H17V61H19V55Z" fill="black" />
                        <path d="M26 55H24V61H26V55Z" fill="black" />
                        <path d="M33 55H31V61H33V55Z" fill="black" />
                        <path d="M40 55H38V61H40V55Z" fill="black" />
                        <path d="M47 55H45V61H47V55Z" fill="black" />
                        <path d="M9 45H3V47H9V45Z" fill="black" />
                        <path d="M9 38H3V40H9V38Z" fill="black" />
                        <path d="M9 31H3V33H9V31Z" fill="black" />
                        <path d="M9 24H3V26H9V24Z" fill="black" />
                        <path d="M9 17H3V19H9V17Z" fill="black" />
                        <path d="M61 45H55V47H61V45Z" fill="black" />
                        <path d="M61 38H55V40H61V38Z" fill="black" />
                        <path d="M61 31H55V33H61V31Z" fill="black" />
                        <path d="M61 24H55V26H61V24Z" fill="black" />
                        <path d="M61 17H55V19H61V17Z" fill="black" />
                    </svg>

                    <h2>Upload Complete!</h2>
                    <p>You will receive an email when rendering is complete, don't be afraid to leave this page.</p>
                </div>
                <div className="uploadBtns">
                    <button onClick={handleDone}>Continue</button>
                </div>
            </div>

            <div className="overlay" style={{ display: showConfirmation ? 'block' : 'none' }}></div>
            <div className="overlay" style={{ display: showUploadComp ? 'block' : 'none' }}></div>
            <div className="overlay" style={{ display: showError ? 'block' : 'none' }}></div>
        </div>
    )
}

export default Network;