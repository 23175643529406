
import react, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { signOut } from 'aws-amplify/auth';
import '../css/dashStyle.css';
import logo from '../images/logo.png';
import Profile from './Profile';
import Payment from './Payment';
import Network from './Network';
import PayMethods from './PayMethods';
import CreditCardOutline from '../images/icons/CreditCardOutline.png';
import GlobeAltOutline from '../images/icons/GlobeAltOutline.png';
import LogoutOutline from '../images/icons/LogoutOutline.png';
import UserCircleOutline from '../images/icons/UserCircleOutline.png';

function Dashboard() {
    const [activeTab, setActiveTab] = useState('network');
    const [previousTab, setPreviousTab] = useState(null);

    const handleLogout = async () => {
        try {
            await signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    const handleTabChange = (tab) => {
        setPreviousTab(activeTab);
        setActiveTab(tab);
    };

    const handleTabChange2 = (tab, previousTab = null) => {
        setPreviousTab(previousTab);
        setActiveTab(tab);
    };

    const handleBack = () => {
        setActiveTab(previousTab);
        setPreviousTab(null);
    };

    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="sideNav">
                    <img src={logo} alt="Logo" className="logo-image" />
                    <div className="sideBtns">
                        <button
                            className={activeTab === 'network' ? 'active' : ''}
                            onClick={() => setActiveTab('network')}
                        >
                            <svg className="icon2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21M21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3M21 12H3M12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12M12 21C13.657 21 15 16.97 15 12C15 7.03 13.657 3 12 3M12 21C10.343 21 9 16.97 9 12C9 7.03 10.343 3 12 3M12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12" stroke="#090516" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>Network
                        </button>

                        {/*<button*/}
                        {/*    className={activeTab === 'profile' ? 'active' : ''}*/}
                        {/*    onClick={() => setActiveTab('profile')}*/}
                        {/*>*/}
                        {/*    <img src={UserCircleOutline} alt="User Icon" className="userIcon icon2" />Profile*/}
                        {/*</button>*/}

                        {/*<button*/}
                        {/*    className={activeTab === 'billing' ? 'active' : ''}*/}
                        {/*    onClick={() => setActiveTab('billing')}*/}
                        {/*>*/}
                        {/*    <img src={CreditCardOutline} alt="Credit Card Icon" className="creditIcon icon2" /> Billing*/}
                        {/*</button>*/}
                    </div>
                </div>

                <div className="tabs">
                    {/*{activeTab === 'profile' && (*/}
                    {/*    <Profile onBuyCreditsClick={() => handleTabChange2('billing')} />*/}
                    {/*)}*/}
                    {/*{activeTab === 'billing' && (*/}
                    {/*    <Payment onPaymentMethodsClick={() => handleTabChange('paymentMethods')} />*/}
                    {/*)}*/}
                    {activeTab === 'network' && (
                        <Network />
                    )}
                    {activeTab === 'paymentMethods' && <PayMethods onBack={handleBack} />}
                </div>
            </div>
            <div className="logOutBtn" onClick={handleLogout}>Logout<img src={LogoutOutline} alt="Logout Icon" className="logoutIcon icon" /></div>
        </div>
    )
}

export default Dashboard;